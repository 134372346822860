import React, { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

function Countdown({
  orderTimeStamp,
  deliveryTime, // in minutes
  color = "white",
  state,
  finished = "false",
  isPlanningForLater = "false",
  selectedDay,
  selectedTime
}) {
  const Ref = useRef(null);
  const stateRef = useRef(state);
  const [foodReady, setFoodReady] = useState(false);
  const [timer, setTimer] = useState(null);
  const [hasDaysLeft, setHasDaysLeft] = useState(false); // New state to track days left

  // Update stateRef and log relevant props
  useEffect(() => {
    stateRef.current = state;
    console.log("State:", state);
    console.log("delivery time: ", deliveryTime);
    console.log("Planned for later:", isPlanningForLater);
    console.log("Selected Day:", selectedDay);
    console.log("Selected Time:", selectedTime);
  }, [state, isPlanningForLater, selectedDay, selectedTime]);

  // Calculate remaining time including days
  const getTimeRemaining = (deadline) => {
    const total = deadline - Date.now();
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    return { total, days, hours, minutes, seconds };
  };

  // Update timer state
  const startTimer = (deadline) => {
    const { total, days, hours, minutes, seconds } = getTimeRemaining(deadline);
    if (total >= 0) {
      if (isPlanningForLater === "true" && days >= 1) {
        setHasDaysLeft(true);
        console.log("Timer with days:", `${days}d ${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`);
        setTimer(
          `${days}d ${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`
        );
      } else {
        setHasDaysLeft(false);
        console.log("Timer without days:", `${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`);
        setTimer(
          `${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`
        );
      }
    } else {
      setTimer("00:00:00");
    }
  };

  // Helper function to format time
  const formatTime = (time) => {
    return time > 9 ? time : `0${time}`;
  };

  // Initialize or reset the timer
  const clearTimer = (deadline) => {
    if (Ref.current) clearInterval(Ref.current);
    startTimer(deadline); // Initial call to set timer immediately
    const id = setInterval(() => {
      startTimer(deadline);
      if (Date.now() >= deadline && stateRef.current === "accepted") {
        setFoodReady(true);
        clearInterval(Ref.current);
      }
    }, 1000);
    Ref.current = id;
  };

  // Determine the deadline based on order type
  const getDeadTime = () => {
    let deadlineDate;
    if (isPlanningForLater === "true") {
      if (!selectedDay || !selectedTime) {
        console.error("selectedDay and selectedTime must be set for preorders.");
        return Date.now(); // No countdown
      }
      // Ensure selectedDay is 'YYYY-MM-DD' and selectedTime is 'HH:MM'
      const deadlineString = `${selectedDay}T${selectedTime}:00`;
      deadlineDate = new Date(deadlineString);
      console.log('Preorder Deadline:', deadlineDate);
    } else {
      deadlineDate = new Date(parseInt(orderTimeStamp) + deliveryTime * 60 * 1000);
      console.log('ASAP Deadline:', deadlineDate);
    }

    if (isNaN(deadlineDate.getTime())) {
      console.error("Invalid deadline date:", deadlineDate);
      deadlineDate = Date.now(); // Set to now to avoid issues
    }
    return deadlineDate.getTime();
  };

  // Initialize timer on mount and when dependencies change
  useEffect(() => {
    const deadline = getDeadTime();
    clearTimer(deadline);
    return () => {
      if (Ref.current) clearInterval(Ref.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryTime, isPlanningForLater, selectedDay, selectedTime, orderTimeStamp]);


  // If order is rejected, show a refund message instead of the countdown
  if (state === "\"rejected\"") {
    return (
      <Typography variant="h5" style={{ color: "pink", fontWeight: "bold", margin: 0, textAlign: "center" }}>
        Afvist af restauranten. Beløbet refunderes inden for 5 bankdage.
      </Typography>
    );
  }


  // Render based on state and other props
  if (state === "\"pending\"") {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress size={200} disableShrink sx={{
          "& .MuiCircularProgress-circle": { animationDuration: '10.5s', color: 'blue' } // Changed color for visibility
        }} />
        <Box sx={{
          top: 0, left: 0, bottom: 0, right: 0,
          position: 'absolute', display: 'flex',
          alignItems: 'center', justifyContent: 'center',
        }}>
          <Typography style={{ color }} variant="h4">
            Personalet tjekker din bestilling, vent venligst.
          </Typography>
        </Box>
      </Box>
    );
  }

  if (finished === "\"true\"" && state === "\"accepted\"") {
    return <Typography variant="h5" style={{ color, margin: 0 }}>Maden er klar</Typography>;
  }

  if (state === "\"accepted\"") {
    if (hasDaysLeft) { // Use the new state to conditionally render
      return (
        <Typography variant="h5" style={{ fontWeight: "bold" ,color, margin: 0 }}>
          {selectedDay} kl. {selectedTime}
        </Typography>
      );
    } else {
      if (deliveryTime == 0) {
        return (
          <Typography variant="h6" style={{ color, fontWeight: "bold", margin: 0 }}>
            Restauranten vælger tidspunkt
          </Typography>
        );
      } else {
        return (
          <Typography variant="h2" style={{ color, fontWeight: "bold", margin: 0 }}>
            {timer !== "00:00:00" ? `${timer}` : "Maden er klar"}
          </Typography>
        );
      }
    }
  }

  return null;
}

export default Countdown;
